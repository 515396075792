import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    flexGrow: 1,
  },
  IntroGrid: {
    // height: '650px',
    position: 'relative',
    backgroundColor: theme.palette.secondary.dark,
  },
  leftIntroSide: {
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.secondary.light,
    opacity: '0.6',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 0 28px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 0 28px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '149px 22px 0 135px',
    },
  },
  leftSideContent: {
    zIndex:1,
    marginTop: '55px',
  },
  rightSdeIntroImg: {
    margin: '90px 0 0 auto',
    minWidth: '55%',
    minHeight: '70%',
    maxHeight:"70%",
    maxWidth:"55%",
    float: 'right',
  },
  iconBar: {
    position: 'absolute',
    top: '75%',
    transform: 'translateY(-50%)',
  },
  iconBarLink: {
    display: 'block',
    padding: '8px',
    fontSize: '2px',
  },


}));

export default function Intro() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={classes.IntroGrid}>
        <img src={"./introImg.png"} alt={""} className={classes.rightSdeIntroImg} />
        <Grid item sm={12} md={6} className={classes.leftIntroSide}>
          <Grid container
                direction="column"
                justify="space-evenly"
                alignItems="flex-start"  >
            <Typography variant={"h1"} className={classes.leftSideContent}>
              The future<br/>
              starts with us
            </Typography>
            <Typography variant={"subtitle1"} className=  {classes.leftSideContent}>
              The Aring is a business-oriented network that brings bright minds together into a single platform, offers a set of services to help them accomplish their success.
            </Typography>
          </Grid>

        </Grid>
        <div className = {classes.iconBar}>
          <Link  href={"#"} className={classes.iconBarLink}>
            <SvgIcon >
              <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.55245 2.92324H8V0.506447C7.29913 0.434511 6.59492 0.398995 5.89028 0.400047C3.79596 0.400047 2.36381 1.66165 2.36381 3.97204V5.96324H0V8.66883H2.36381V15.6H5.19731V8.66883H7.55342L7.9076 5.96324H5.19731V4.23804C5.19731 3.44004 5.4129 2.92324 6.55245 2.92324Z" fill="#E13224"/>
              </svg>
            </SvgIcon>
          </Link>
          <Link href={"#"}  className={classes.iconBarLink}>
            <SvgIcon >
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.09195 11.8V4.24335H0.549883V11.8H3.09195ZM1.82124 3.21101C2.70771 3.21101 3.25949 2.63075 3.25949 1.90561C3.24297 1.16413 2.70774 0.599976 1.83807 0.599976C0.968528 0.599976 0.399902 1.16414 0.399902 1.90561C0.399902 2.63078 0.951547 3.21101 1.80465 3.21101H1.82116H1.82124ZM4.49897 11.8H7.04104V7.57999C7.04104 7.35414 7.05755 7.12853 7.12468 6.96708C7.30846 6.51584 7.72673 6.04849 8.42895 6.04849C9.34882 6.04849 9.71682 6.74146 9.71682 7.75729V11.7999H12.2587V7.46702C12.2587 5.14593 11.0046 4.06595 9.33208 4.06595C7.96074 4.06595 7.35863 4.82331 7.02413 5.33915H7.0411V4.2432H4.49903C4.53239 4.95227 4.49903 11.7998 4.49903 11.7998L4.49897 11.8Z" fill="#E13224"/>
              </svg>
            </SvgIcon>
          </Link>
          <Link href={"#"}  className={classes.iconBarLink}>
            <SvgIcon >
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.93896 10.4946C8.9874 11.3711 7.68897 11.8867 6.13975 11.8867C3.89678 11.8867 1.95615 10.6 1.01162 8.72502C0.622559 7.94924 0.399902 7.07268 0.399902 6.14456C0.399902 5.21643 0.622559 4.33987 1.01162 3.56409C1.95615 1.68674 3.89678 0.400024 6.13975 0.400024C7.68662 0.400024 8.98506 0.969556 9.98115 1.89534L8.33584 3.54299C7.74053 2.97346 6.9835 2.68518 6.14209 2.68518C4.64678 2.68518 3.38115 3.69534 2.92881 5.05237C2.81396 5.3969 2.74834 5.76487 2.74834 6.14456C2.74834 6.52424 2.81396 6.89221 2.92881 7.23674C3.38115 8.59377 4.64678 9.60393 6.13975 9.60393C6.91318 9.60393 7.56943 9.40002 8.08271 9.05549C8.69209 8.64768 9.09521 8.04065 9.22881 7.32112H6.13975V5.09924H11.5468C11.6147 5.47659 11.6499 5.86799 11.6499 6.27346C11.6499 8.02424 11.0241 9.49377 9.93896 10.4946Z" fill="#E13224"/>
              </svg>
            </SvgIcon>
          </Link>
        </div >
      </Grid>
    </div>
  );
}
