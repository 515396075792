import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import MenuAppBar from './components/MenuAppBar'
import Intro from './components/Intro'
import About from './components/About'
import Subscribe from './components/Subscribe'
import Footer from './components/Footer'

const theme = createMuiTheme({
  // typography:{
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Jost',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  // },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '1.5rem',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:600px)': {
        fontSize: '3.5rem',
      },
      '@media (min-width:960px)': {
        fontSize: '4rem',
      },
    },
    subtitle1: {
      fontSize: '0.3rem',
      '@media (max-width:600px)': {
        fontSize: '0.6rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1.3rem',
      },
      '@media (min-width:960px)': {
        fontSize: '18px',
      },
    },
  }
  ,
  palette: {
    primary: {
      main: '#e13224',
      light: '#ff694f',
      dark: '#a70000',
    },
    secondary: {
      main: '#F3F3F3',
      light: '#ffffff',
      dark: '#c0c0c0',
    },
  },
});


function App () {

  return (
    <ThemeProvider theme = {theme}>
      <MenuAppBar />
      <Intro />
      <Subscribe />
      <About />
      <Footer />
    </ThemeProvider >
  )
}

export default App
