import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import aws from '../services/aws'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    flexGrow: 1,
  },
  formControl: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(5),
  },
  leftMargin: {
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0px 0 0px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px 0px 0 0px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '15px 15px 0 50px',
    },
  },
  subscribeText: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(11),
    },
  },
  formMinWidth85: {
    minWidth: '85%',
  },
  formMinWidth70: {
    marginRight:0,
    minWidth: '60%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '60px',
    },
  },
  joinBtn:{
    padding:theme.spacing(1.9),
    paddingRight:theme.spacing(6),
    paddingLeft:theme.spacing(6),
    marginTop:theme.spacing(5),
    marginLeft:theme.spacing(-0.3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    }
  },
  containerPadding:{
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '40px'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '90px'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0'
    },
  }
}));

export default function About() {
  const classes = useStyles();

  const [agreeToEmail,setAgreeToEmail] = useState(false);
  const [email,setEmail] = useState('');
  const [proficiency,setProficiency] = useState('Entrepreneurs');

  const onCountMeInClick = async () => {
    if (email !== '') {
      aws.invokeApi({}, '/test/email', 'post', {}, { email,  proficiency }).then((result) => {
        if (result.status === 200)
        {
          alert(`Thanks - We´ll be in touch soon!`)
        }
      }).catch( (result) => {
        alert("there was a problem");
      })
    } else
      alert('add your email')
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.containerPadding} >
        <Grid item xs={12} sm={12} md={2} className={classes.leftMargin}>
          <Typography variant={'h1'} className={classes.subscribeText} >
            I am
          </Typography>
         </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormControl variant="outlined" className={`${classes.formControl} ${classes.formMinWidth85}`}>
            <InputLabel id="demo-simple-select-outlined-label">Choose</InputLabel>
            <Select autoWidth={true}
                    value={proficiency}
                    onChange={(e) => {setProficiency(e.target.value)}}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Choose"
            >
              <MenuItem value={'Entrepreneurs'}>Entrepreneurs</MenuItem>
              <MenuItem value={'Investor'}>Investor</MenuItem>
              <MenuItem value={'Subject Matter Expert'}>Subject Matter Expert</MenuItem>
              <MenuItem value={'NGO Representative'}>NGO Representative</MenuItem>
              <MenuItem value={'Influencer'}>Influencer</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <TextField label="Enter your email" variant="outlined" value={email} onChange={(e)=> setEmail(e.target.value)} className={`${classes.formControl} ${classes.formMinWidth70}`} />
          <Button variant="contained" color="primary" className={classes.joinBtn} onClick={onCountMeInClick} disabled={!agreeToEmail}>
            Count me in
          </Button >
          <FormControlLabel style={{margin:'30px 90px 0 0'}}
            control={
              <Checkbox
                checked={agreeToEmail}
                onChange={(e) =>setAgreeToEmail( e.target.checked)}
                name="Agree"
                color="primary"
              />
            }
            label="I confirm that TheAring will send me emails about the MVP, and future services and products."
          />
        </Grid>
      </Grid>
    </div>
  );
}
