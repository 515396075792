import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(10),
  },
  title: {
    flexGrow: 1,
  },
  imgBrand: {
    marginLeft:theme.spacing(14)
  },
  btnMod:{textTransform:"none",marginRight:"10px"}
}));

export default function MenuAppBar() {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <AppBar position="static" >
        <Toolbar>
          <img src={"./logo.svg"} className={classes.imgBrand}  alt={""} height={"49px"}/>
          <Typography variant="h6" className={classes.title}>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
