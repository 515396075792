import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core'
import ReactPlayer from 'react-player'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    flexGrow: 1,
  },
  dotsImg:{
    position: "relative",
    top: "-37px",
    left: "40px",
  },
  aboutHeaderText:{
    display:"inline",
    position: "relative",
    top: "-25px",
    marginLeft:"135px",
    [theme.breakpoints.up('xs')]: {
      marginLeft:"30px",
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft:"80px",
    },
    [theme.breakpoints.up('md')]: {
      marginLeft:"135px",
    },
  },
  pText:{
    // marginTop:"20px",
    color: theme.palette.secondary.dark,
    padding:"30px"
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={"./aboutDots.svg"} alt={""} className={classes.dotsImg} />
      <Typography variant={'h3'} className={classes.aboutHeaderText} >
        About us
      </Typography>
      <Grid container spacing={0} >
        <Grid item sm={12} md={6}>
          <ReactPlayer width={"70%"} height={"90%"} controls={true} url='https://youtu.be/ReUHHUfozrU' style={{ margin:"40px 0 0 135px" }} />
        </Grid>
        <Grid item sm={12} md={5}>
          <Grid container
                direction="column"
                justify="flex-start"
                alignItems="flex-start" style={{marginTop:"30px"}} >
            <Typography variant={"body1"}  className={classes.pText} >
              TheAring is a business-oriented network that brings bright minds together into a single platform, offers a set of services to help them accomplish their success. TheAring is a business-oriented network that brings bright minds together into a single platform, offers a set of services to help them accomplish their success.
            </Typography>
            <Typography variant={"body1"}  className={classes.pText}>
              TheAring is a business-oriented network that brings bright minds together into a single platform, offers a set of services to help them accomplish their success.
            </Typography>
            <Typography variant={"body1"} className={classes.pText} >
              TheAring is a business-oriented network that brings bright minds together into a single platform, offers a set of services to help them accomplish their success.
            </Typography>
          </Grid>

        </Grid>
      </Grid>
    </div>
  );
}
