var apigClientFactory = require('aws-api-gateway-client').default

const apigClient = apigClientFactory.newClient({
  invokeUrl: 'https://grkgame3vj.execute-api.eu-west-1.amazonaws.com',
  region: 'eu-west-1',
  accessKey: 'AKIA36GSFKF5WLR3EJDQ',
  secretKey: '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',
});


export default apigClient;
